/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete =
  $.fn.uvoAutocomplete ||
  function() {
    return this.each(function(_, el) {
      if (el.uvoAutocompleteShimInitialized) return;
      el.uvoAutocompleteShimInitialized = true;
      var $el = $(el);
      var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
      var $icon = $(
        '<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />',
      );
      $el.wrap($wrapper);
      $el.after($icon);
      $el.addClass('uvo_pref-uvoAutocomplete-input');
      $el.css({ appearance: 'none', '::-ms-expand': 'none' });
      $icon.css({ pointerEvents: 'none' });
    });
  };

/**
 * Description: JS for buyessays.net
 * Version: 1.0.0
 * Last update: 2015/05/01
 * Author: UVOCorp
 *
 * Summary:
 *
 *	0. matchMedia() polyfill
 *		- Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas. Dual MIT/BSD license
 *	1. Respond.js
 *		- min/max-width media query polyfill.
 *	2. Custom JS for
 *
 */

/* ==========================================================================
   2. Custom JS for
   ========================================================================== */

$(function() {
  if ($('.testimonials-slider').length) {
    $('.testimonials-slider').owlCarousel({
      items: 1,
      nav: true,
      navText: ['', ''],
      loop: true,
    });
  }

  if ($('.last-projects-slider').length) {
    $('.last-projects-slider').owlCarousel({
      items: 5,
      nav: true,
      navText: ['', ''],
      loop: true,
      dots: false,
      responsive: {
        1199: {
          items: 5,
        },
        980: {
          items: 3,
        },
        768: {
          items: 2,
        },
        0: {
          items: 1,
        },
      },
    });
  }

  //add class "selected" for item select
  if ($.fn.uvoAutocomplete) {
    $('.place-your-order select').uvoAutocomplete({
      onOpen: function(e) {
        var lebelEl = this.label;
        $(this._listElement.children()).each(function() {
          if ($(this).text() == lebelEl) {
            $(this).addClass('selected');
          }
        });
      },
      sortBy: 'none',
    });
    $(
      '.place-your-order select[name=academic_level], .place-your-order select[name=deadline]',
    ).uvoAutocomplete({
      sortBy: 'none',
    });
  }

  //count pages
  $.fn.counts = function(options) {
    //defaultOptions
    options = $.extend(
      {
        minusClass: 'pageskol-left',
        minimum: 1,
      },
      options,
    );
    var inputCol = $(this);
    if ($(inputCol).val() <= options.minimum) {
      $('span[class=' + options.minusClass + ']').css({
        opacity: '0.5',
        cursor: 'default',
      });
    } else {
      $('span[class=' + options.minusClass + ']').css({
        opacity: '1',
        cursor: 'pointer',
      });
    }
    $(inputCol)
      .parent()
      .find('span')
      .click(function() {
        if ($(this).hasClass(options.minusClass)) {
          if ($(inputCol).val() >= options.minimum) {
            var count = parseInt($(inputCol).val()) - 1;
            count = count < options.minimum ? options.minimum : count;
          } else {
            $(inputCol).val(options.minimum - 1);
            var count = parseInt($(inputCol).val()) - 1;
            count = count < options.minimum ? options.minimum : count;
          }
          if ($(inputCol).val() <= options.minimum + 1) {
            $('span[class=' + options.minusClass + ']').css({
              opacity: '0.5',
              cursor: 'default',
            });
          }
        } else {
          if ($(inputCol).val() >= options.minimum) {
            var count = parseInt($(inputCol).val()) + 1;
          } else {
            $(inputCol).val(options.minimum - 1);
            var count = parseInt($(inputCol).val()) + 1;
          }
          if ($(inputCol).val() > options.minimum - 1) {
            $('span[class=' + options.minusClass + ']').css({
              opacity: '1',
              cursor: 'pointer',
            });
          }
        }
        $(inputCol).val(count);
        $(inputCol).change();
        $(this)
          .parent()
          .next()
          .find('span')
          .html(275 * count);
        return false;
      });
    $(this).change(function(e) {
      var count = parseInt($(this).val());
      if (isNaN(count)) {
        count = 1;
        $(this).val(1);
      }
      $(this)
        .parent()
        .next()
        .find('span')
        .html(275 * count);
    });
    $(this).focusout(function(e) {
      var count = parseInt($(this).val());
      if (isNaN(count)) {
        count = 1;
        $(this).val(1);
      }
      $(this)
        .parent()
        .next()
        .find('span')
        .html(275 * count);
    });
    $(this).keydown(function(e) {
      if (
        e.which != 8 &&
        e.which != 0 &&
        (e.which < 37 || e.which > 57) &&
        e.which != 96 &&
        e.which != 97 &&
        e.which != 98 &&
        e.which != 99 &&
        e.which != 100 &&
        e.which != 101 &&
        e.which != 102 &&
        e.which != 103 &&
        e.which != 104 &&
        e.which != 105
      ) {
        return false;
      }
      var key, keyChar;
      if (!e) var e = window.e;
      if (e.keyCode) key = e.keyCode;
      else if (e.which) key = e.which;
      if (key == 8) {
        return true;
      }
      if (
        key == 38 ||
        key == 39 ||
        ((e.which < 48 || e.which > 57) &&
          e.which != 96 &&
          e.which != 97 &&
          e.which != 98 &&
          e.which != 99 &&
          e.which != 100 &&
          e.which != 101 &&
          e.which != 102 &&
          e.which != 103 &&
          e.which != 104 &&
          e.which != 105)
      ) {
        if (key == 40 || key == 37) {
          if ($(this).val() >= options.minimum) {
            var count = parseInt($(this).val()) - 1;
            count = count < options.minimum ? options.minimum : count;
          } else {
            $(this).val(options.minimum - 1);
            keyChar = String.fromCharCode(key);
            var count = parseInt($(this).val()) + 1;
          }
        } else {
          if ($(this).val() >= options.minimum) {
            var count = parseInt($(this).val()) + 1;
          } else {
            $(this).val(options.minimum - 1);
            var count = parseInt($(this).val()) + 1;
          }
        }
        $(this).val(count);
        $(this)
          .parent()
          .next()
          .find('span')
          .html(275 * count);
        if ($(inputCol).val() <= options.minimum) {
          $('span[class=' + options.minusClass + ']').css({
            opacity: '0.5',
            cursor: 'default',
          });
        } else {
          $('span[class=' + options.minusClass + ']').css({
            opacity: '1',
            cursor: 'pointer',
          });
        }
      }
    });
  };
  //counts
  $('#input-kol').counts();

  // Inquiry page
  $('.show-hide')
    .find('.hidden')
    .hide();
  $('#inq-done-more').click(function() {
    if (!$(this).hasClass('less')) {
      $(this)
        .parents('.show-hide')
        .find('.hidden')
        .slideDown(500);
      $(this)
        .text('Read less')
        .addClass('less');
    } else {
      $(this)
        .parents('.show-hide')
        .find('.hidden')
        .slideUp(500);
      $(this)
        .text('Read more')
        .removeClass('less');
    }
  });
  var pl_panel = $('.plagcheckc aside#rightplagpanel').clone();
  $(pl_panel)
    .removeAttr('id')
    .appendTo('.plagcheckc')
    .attr('id', 'rightplagpanel_2');

  //toggle topmenu
  $('.burger').on('click', function() {
    $(this).toggleClass('active');
    $('#navigation').fadeToggle();
  });

  // white header onscroll
  var header = $('.header');

  function headerScrollChange() {
    if ($(window).scrollTop() > header.outerHeight()) {
      header.addClass('header--white');
    } else {
      header.hasClass('header--white') ? header.removeClass('header--white') : false;
    }
  }

  window.addEventListener('load', headerScrollChange);
  window.addEventListener('scroll', headerScrollChange);
  window.addEventListener('resize', headerScrollChange);

  $(document).on('ready', function() {
    var $body = $('body'),
      $header = $('.header'),
      $menu = $('.topmenu'),
      $burger = $('.burger'),
      $nav = $burger.next();

    $(window).on('resize', function() {
      if ($(this).width() >= 992) {
        $burger.removeClass('active');
        $nav.removeAttr('style');
      }
    });
  });
});

(function toggleListInit() {
  if (
    !document.querySelector('[data-page="frequently_asked_questions"]') &&
    !document.querySelector('[data-page="money_back_guarantee"]')
  )
    return false;

  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function() {
      if (
        $(this)
          .next()
          .hasClass('hide')
      ) {
        $(this)
          .next()
          .slideDown(500);
      } else {
        $(this)
          .next()
          .slideUp(500);
      }
      $(this)
        .parent()
        .toggleClass('active');
      $(this)
        .next()
        .toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('contentRendered', toggle_list);
})();

(function(window, document) {
  function init() {
    var element = document.getElementById('CommonPage');

    if (element) {
      var pageName = element.getAttribute('data-page');
      var siteId = element.getAttribute('data-site-id');
      var policies = element.getAttribute('data-policies');
      var policyId = element.getAttribute('data-policy-id');

      let widgetOptions = {
        siteId,
        pageName,
      };

      if (policies && policyId) {
        widgetOptions = { siteId, pageName, policies, policyId };
      }

      window.commonPagesWidget.widget(element, widgetOptions);
    }
  }

  if (typeof window.commonPagesWidget !== 'undefined') {
    init();
  } else {
    var script = document.createElement('script');
    script.src = '/assets/js/commonPagesWidget.min.js';
    script.async = true;
    script.onload = function() {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

/* =============================
*  NEW CALC INIT
================================ */
document.addEventListener('DOMContentLoaded', function() {
  $.getScript('/assets/js/calc.js', function() {
    //CALC SHORT
    var defaultOptions = {
      deadlinesDefault: [66283],
      academicLevelDefault: 2,
      paperTypesDefault: 2,
      pagesDefault: 1,
    };

    if ($('[data-calc-short]').length > 0) {
      new Calc({
        ID: '143',
        calculatorType: 'ShortCalculator',
        format: 'json',
        options: {
          deafault: defaultOptions,
        },
        callback: function() {
          enableSubmitBtn($('[data-calc-short]'));
        },
      });
    }

    //CALC MINI
    if ($('[data-calc-mini]').length > 0) {
      new Calc({
        ID: '143',
        calculatorType: 'DefaultCalculator',
        format: 'json',
        options: {
          deafault: defaultOptions,
        },
        callback: function() {
          enableSubmitBtn($('[data-calc-mini]'));
        },
      });
    }

    function enableSubmitBtn(form) {
      $(form)
        .find('.sbmt-btn')
        .attr('disabled', false);
    }
  });
});

/* =============================K
*  end NEW CALC INIT
================================ */

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '143',
      container: '.NotifyWidget-banner',
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function() {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);
